import { useState, useEffect } from "react";
// axios
import axios from "axios";
// react-router-dom
import { useParams } from "react-router-dom";
// store
import useStore from "../../store";
// components
import Loading from "../Loading/Loading";
// css
import "./products.css";

export default function Products() {
  const { id } = useParams();

  const [isFetched, setIsFetched] = useState(false);

  const { products, setProducts, selectedCategory } = useStore(
    (state) => state
  );

  useEffect(() => {
    if (selectedCategory) {
      setIsFetched(false);
      fetchProducts();
    }
    // eslint-disable-next-line
  }, [selectedCategory]);

  async function fetchProducts() {
    axios({
      url: `${
        process.env.REACT_APP_API_URL
      }online-menu/${id}/dishes?time=${new Date().getTime()}`,
      params: {
        company_id: id,
        menu_id: selectedCategory,
        per_page: 100,
        include: "file",
      },
      method: "GET",
    })
      .then((response) => {
        if (response?.data?.data) {
          setProducts(response.data.data);
        }
        setIsFetched(true);
      })
      .catch(function (error) {
        console.log(error);
        setIsFetched(true);
      });
  }

  return (
    <div className="">
      <div className="my-[70px]">
        {products?.length > 0 ? (
          <div className="grid gap-[20px] grid-cols-12">
            {isFetched &&
              products.map((product) => (
                <Item key={product.id} product={product} />
              ))}
          </div>
        ) : (
          <div className=" flex flex-row items-center justify-center no-food">
            <img src={process.env.PUBLIC_URL + "/no-food.png"} alt="loading" />
          </div>
        )}
        {!isFetched && <Loading />}
      </div>
    </div>
  );
}

const Item = ({ product }) => {
  return (
    <div className="product-item-wrapper col-span-6 sm:col-span-6 lg:col-span-4">
      <div className="image-wrapper">
        {product?.file ? (
          <img src={product?.file?.thumbnails?.normal?.src} alt="logo" />
        ) : (
          "No Photo"
        )}
      </div>

      <div className="description-wrapper">
        <h3>{product?.name}</h3>
        <p className="description">{product?.translate?.description}</p>
        <p className="price">{product?.price.toLocaleString()} сум</p>
      </div>
    </div>
  );
};
