import { useState, useEffect } from "react";
// axios
import axios from "axios";
// react-router-dom
import { useParams } from "react-router-dom";
// store
import useStore from "../../store";
// react-modal
import Modal from "react-modal";
// components
import Categories from "./Categories";
import Products from "./Products";
import Invoice from "../Invoice/Invoice";

export default function MenuItems() {
  // here get id from the url
  const { id } = useParams();
  const { setCompany, setCategories, showInvoice, setShowInvoice } = useStore(
    (state) => state
  );

  const [isFetched, setIsFetched] = useState(false);

  useEffect(() => {
    if (id && !isFetched) fetchMenus();
    // eslint-disable-next-line
  }, [id]);

  useEffect(() => {
    fetchCompany();
    // eslint-disable-next-line
  }, []);

  async function fetchMenus() {
    axios({
      url: `${process.env.REACT_APP_API_URL}online-menu/${id}/menus`,
      params: {
        company_id: id,
        per_page: 100,
        time: new Date().getTime(),
        include: "children",
      },
      method: "GET",
    })
      .then((response) => {
        if (response?.data?.data) {
          setCategories(response.data.data);
        }
        setIsFetched(true);
      })
      .catch(function (error) {
        console.log(error);
        setIsFetched(true);
      });
  }

  async function fetchCompany() {
    axios({
      url: `${process.env.REACT_APP_API_URL}online-menu/${1}/companies`,
      method: "GET",
    })
      .then((response) => {
        if (response?.data) {
          const canSee = response?.data?.qrcode_read;

          if (!canSee) {
            // window.location.href = `/`;
          }

          setCompany(response.data);
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  return (
    <div className="container">
      <div className="">
        <h2 className="text-[25px] font-bold mt-[20px] mb-[15px]">Меню:</h2>
        <Categories />

        <Products />

        <Modal
          isOpen={showInvoice}
          // onAfterOpen={afterOpenModal}
          // onRequestClose={closeModal}
          // style={customStyles}
          contentLabel="Example Modal"
        >
          <div
            onClick={() => setShowInvoice(false)}
            className="flex flex-row items-center justify-center absolute top-0 right-0 m-[10px] cursor-pointer"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              x="0px"
              y="0px"
              width="25"
              height="25"
              viewBox="0 0 50 50"
            >
              <path d="M 7.71875 6.28125 L 6.28125 7.71875 L 23.5625 25 L 6.28125 42.28125 L 7.71875 43.71875 L 25 26.4375 L 42.28125 43.71875 L 43.71875 42.28125 L 26.4375 25 L 43.71875 7.71875 L 42.28125 6.28125 L 25 23.5625 Z"></path>
            </svg>
          </div>
          <Invoice />
        </Modal>
      </div>
    </div>
  );
}
