// components
import QrCodeGenerator from "../components/QrCodeGenerator/QrCodeGenerator";

export default function QrCode() {


  return (
    <div>
      <QrCodeGenerator/>
    </div>
  );
}
