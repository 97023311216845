import { useEffect } from "react";
// axios
import axios from "axios";
// notistack
import { enqueueSnackbar } from "notistack";
// react-router-dom
import { useLocation } from "react-router-dom";
// store
import useStore from "../../store";
// scss
import "./header.css";

export default function Header() {
  const { user, company, orders, setOrders, setShowInvoice } = useStore(
    (state) => state
  );

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const tableId = queryParams.get("table_id");

  // get current path last after /
  const path = location.pathname.split("/").pop();
  console.log("id", path);

  // function to send api call
  async function callWaiter() {
    axios({
      url: `${process.env.REACT_APP_API_URL}online-menu/tables/${tableId}/waiter/call`,
      // body request
      data: {
        table_id: tableId,
        organization_id: path,
      },
      method: "POST",
    })
      .then((response) => {
        enqueueSnackbar("Официант вызван");
      })
      .catch(function (error) {
        enqueueSnackbar(error?.response?.data?.message);
      });
  }

  return (
    <>
      {path === "qr-code" ? (
        <div></div>
      ) : (
        <div className="navbar bg-[#a5141a] py-[10px] rounded-b-[12px]">
          <div className="container">
            <div className="flex flex-row items-center justify-between">
              {company?.logo?.thumbnails?.normal?.src ? (
                <img
                  className="w-[110px] h-auto rounded-[6px]"
                  src={company?.logo?.thumbnails?.normal?.src}
                  alt="logo"
                />
              ) : (
                <div className="text-[24px] text-[#fff] font-semibold">
                  {company?.translate?.name}
                </div>
              )}

              <div className="flex flex-col">
                <div
                  onClick={callWaiter}
                  className="bg-[#fff] text-[#a5141a] px-[20px] py-[5px] rounded-[6px] cursor-pointer"
                >
                  Ofitsiantni chaqirish
                </div>
                {orders?.length > 0 && (
                  <div
                    onClick={() => setShowInvoice(true)}
                    className="bg-[#fff] text-[#a5141a] px-[20px] py-[5px] rounded-[6px] cursor-pointer mt-[15px]"
                  >
                    Hisobni ko'rish
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}
