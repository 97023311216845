import { useState, useEffect } from "react";
// axios
import axios from "axios";
// react-router-dom
import { useLocation } from "react-router-dom";
// store
import useStore from "../../store";
// react-qrcode-logo
import { QRCode } from "react-qrcode-logo";
// css
import "./qrCodeGenerator.css";

const QrCodeGenerator = () => {
  const [ids, setIds] = useState([]);

  const { company, setCompany } = useStore((state) => state);

  // in search params we get 4 params
  // 1. company_id - Company id for fetching data
  // 2. lang - For changing the content based on language
  // 3. bgColorOne - for setting background color (main)
  // 4. bgColorOne - for setting background color (secondary)
  // 5. color - for setting text's color
  // 6. revert - for changing the order of tables

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);

  const companyId = queryParams.get("company_id");

  const lang = queryParams.get("lang") || "ru";
  let bgColorOne = queryParams.get("bgColorOne") || "#eb483e";
  let bgColorTwo = queryParams.get("bgColorTwo") || "#f7a072";
  let color = queryParams.get("color") || "#eb483e";
  const revert = queryParams.get("revert") === "true";

  // check all color if it doesn't have # and doesn't starts with rgb
  if (
    !bgColorOne.startsWith("#") &&
    !bgColorOne.startsWith("rgb") &&
    !bgColorOne.startsWith("rgba")
  ) {
    bgColorOne = `#${bgColorOne}`;
  }

  if (
    !bgColorTwo.startsWith("#") &&
    !bgColorTwo.startsWith("rgb") &&
    !bgColorTwo.startsWith("rgba")
  ) {
    bgColorTwo = `#${bgColorTwo}`;
  }

  if (
    !color.startsWith("#") &&
    !color.startsWith("rgb") &&
    !color.startsWith("rgba")
  ) {
    color = `#${color}`;
  }

  const instagram = company?.instagram;
  const facebook = company?.facebook;
  const telegram = company?.telegram;
  const companyPhone = company?.phone;

  const texts = {
    ru: {
      title:
        "Сканируйте QR код для просмотра меню, вызова официанта и просмотра счета",
      automatization: "Автоматизация ресторанов",
      menu: "Меню",
      contact: "Контактный телефон:",
    },
    uz: {
      title:
        "Menu ko'rish, ofitsiant chaqirish va hisobni ko'rish uchun QR-kodni skaner qiling",
      automatization: "Restoranlarni avtomatlashtirish",
      menu: "Menu",
      contact: "Biz bilan bog'laning:",
    },
    en: {
      title:
        "Scan the QR code to view the menu, call the waiter and view the bill",
      automatization: "Restaurant automation",
      menu: "Menu",
      contact: "Contact phone number:",
    },
  };

  useEffect(() => {
    fetchTables();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (companyId) {
      fetchCompany();
    }
    // eslint-disable-next-line
  }, [companyId]);

  const generateSVG = (number) => {
    return `
          <svg xmlns="http://www.w3.org/2000/svg" width="100" height="100" viewBox="0 0 100 100">
            <circle cx="50" cy="50" r="45" fill="black" stroke="black" stroke-width="5"/>
            <text x="50" y="65" font-size="50" text-anchor="middle" font-weight="600" fill="white">${number}</text>
          </svg>
        `;
  };

  const svgToDataURL = (svg) => {
    return `data:image/svg+xml;base64,${btoa(svg)}`;
  };

  function fetchTables() {
    axios
      .get(`${process.env.REACT_APP_API_URL}online-menu/${companyId}/tables`, {
        params: {
          company_id: companyId,
          per_page: 1000,
          include: "place",
        },
      })
      .then(function (response) {
        if (response?.data?.data) {
          let data = response.data.data;
          if (revert) {
            for (let i = 0; i < data.length - 1; i += 2) {
              // Меняем местами элементы
              [data[i], data[i + 1]] = [data[i + 1], data[i]];
            }
          }
          setIds(data);
        }
      })
      .catch(function (error) {
        console.log(error);
      })
      .finally(function () {});
  }

  async function fetchCompany() {
    axios({
      url: `${process.env.REACT_APP_API_URL}online-menu/${companyId}/companies`,
      params: {
        include: "logo",
      },
      method: "GET",
    })
      .then((response) => {
        if (response?.data) {
          const canSee = response?.data?.qrcode_print;
          console.log("canSee", response?.data);

          if (!canSee) {
            // window.location.href = `/${companyId}`;
          }

          setCompany(response.data);
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  return (
    <div className="qr-grid-wrapper">
      <div className="qr-grid">
        {ids.map((item) => {
          const svg = generateSVG(item.number);
          const imageUrl = svgToDataURL(svg);
          return (
            <div
              key={item.id}
              id={`qr-${item.id}`}
              className="qr-item"
              style={{
                "--bgColorOne": bgColorOne,
                "--bgColorTwo": bgColorTwo,
                "--color": color,
              }}
            >
              <img
                src={company?.logo?.thumbnails?.normal?.src}
                alt=""
                className="logo"
              />

              <div className="description">
                <p>{texts[lang]?.title}</p>
              </div>
              <div className="qr-item__inner">
                <div className="number">
                  <p>{item.number}</p>
                  {/* <p className="place">{item?.place?.title_ru}</p> */}
                  <p className="place">{item?.place[`title_${lang}`]}</p>
                </div>
                <div className="qr">
                  <QRCode
                    value={`http://menu-kafe.zim-zim.uz/${companyId}?table_id=${item.id}`}
                    size={105}
                    // logoImage={imageUrl}
                    // logoWidth={50}
                    // logoHeight={50}
                    // removeQrCodeBehindLogo
                    bgColor="white"
                    fgColor={color}
                    eyeRadius={10}
                    // transparent background
                    logoPaddingStyle="circle"
                    style={{
                      borderRadius: "10px",
                    }}
                    qrStyle="dots"
                    renderAs="svg"
                  />
                  <p className="badge">{texts[lang]?.menu}</p>
                </div>
              </div>
              <div className="socials">
                <div className="socials-inner">
                  {instagram && (
                    <div className="instagram social">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 448 512"
                      >
                        <path
                          fill={color}
                          d="M224.1 141c-63.6 0-114.9 51.3-114.9 114.9s51.3 114.9 114.9 114.9S339 319.5 339 255.9 287.7 141 224.1 141zm0 189.6c-41.1 0-74.7-33.5-74.7-74.7s33.5-74.7 74.7-74.7 74.7 33.5 74.7 74.7-33.6 74.7-74.7 74.7zm146.4-194.3c0 14.9-12 26.8-26.8 26.8-14.9 0-26.8-12-26.8-26.8s12-26.8 26.8-26.8 26.8 12 26.8 26.8zm76.1 27.2c-1.7-35.9-9.9-67.7-36.2-93.9-26.2-26.2-58-34.4-93.9-36.2-37-2.1-147.9-2.1-184.9 0-35.8 1.7-67.6 9.9-93.9 36.1s-34.4 58-36.2 93.9c-2.1 37-2.1 147.9 0 184.9 1.7 35.9 9.9 67.7 36.2 93.9s58 34.4 93.9 36.2c37 2.1 147.9 2.1 184.9 0 35.9-1.7 67.7-9.9 93.9-36.2 26.2-26.2 34.4-58 36.2-93.9 2.1-37 2.1-147.8 0-184.8zM398.8 388c-7.8 19.6-22.9 34.7-42.6 42.6-29.5 11.7-99.5 9-132.1 9s-102.7 2.6-132.1-9c-19.6-7.8-34.7-22.9-42.6-42.6-11.7-29.5-9-99.5-9-132.1s-2.6-102.7 9-132.1c7.8-19.6 22.9-34.7 42.6-42.6 29.5-11.7 99.5-9 132.1-9s102.7-2.6 132.1 9c19.6 7.8 34.7 22.9 42.6 42.6 11.7 29.5 9 99.5 9 132.1s2.7 102.7-9 132.1z"
                        />
                      </svg>
                      {instagram}
                    </div>
                  )}
                  {facebook && (
                    <div className="facebook social">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 448 512"
                      >
                        <path
                          fill={color}
                          d="M64 32C28.7 32 0 60.7 0 96V416c0 35.3 28.7 64 64 64h98.2V334.2H109.4V256h52.8V222.3c0-87.1 39.4-127.5 125-127.5c16.2 0 44.2 3.2 55.7 6.4V172c-6-.6-16.5-1-29.6-1c-42 0-58.2 15.9-58.2 57.2V256h83.6l-14.4 78.2H255V480H384c35.3 0 64-28.7 64-64V96c0-35.3-28.7-64-64-64H64z"
                        />
                      </svg>
                      {facebook}
                    </div>
                  )}

                  {telegram && (
                    <div className="telegram social">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 496 512"
                      >
                        <path
                          fill={color}
                          d="M248 8C111 8 0 119 0 256S111 504 248 504 496 393 496 256 385 8 248 8zM363 176.7c-3.7 39.2-19.9 134.4-28.1 178.3-3.5 18.6-10.3 24.8-16.9 25.4-14.4 1.3-25.3-9.5-39.3-18.7-21.8-14.3-34.2-23.2-55.3-37.2-24.5-16.1-8.6-25 5.3-39.5 3.7-3.8 67.1-61.5 68.3-66.7 .2-.7 .3-3.1-1.2-4.4s-3.6-.8-5.1-.5q-3.3 .7-104.6 69.1-14.8 10.2-26.9 9.9c-8.9-.2-25.9-5-38.6-9.1-15.5-5-27.9-7.7-26.8-16.3q.8-6.7 18.5-13.7 108.4-47.2 144.6-62.3c68.9-28.6 83.2-33.6 92.5-33.8 2.1 0 6.6 .5 9.6 2.9a10.5 10.5 0 0 1 3.5 6.7A43.8 43.8 0 0 1 363 176.7z"
                        />
                      </svg>
                      {telegram}
                    </div>
                  )}
                </div>

                <div className="contact">
                  {/* <span>{texts[lang].contact}:</span> */}
                  <span>{texts[lang]?.contact}</span>

                  <span>{companyPhone}</span>
                </div>
              </div>
              {/* <div className="contact">
                <div className="contact-inner">
                  <span>{texts[lang].contact}:</span>

                  <span>+998 90 999-90-52</span>
                </div>
              </div> */}
              <div className="zim-zim">
                <img src="https://zim-zim.uz/frontend/images/logo.png" alt="" />
                <div>
                  <span>{texts[lang]?.automatization}</span>

                  <span>+998 90 999-90-52</span>
                </div>
              </div>
            </div>
          );
        })}
      </div>

      {/* <div className="navbar-overlay"></div> */}
      <div
        className="print-button"
        onClick={() => {
          // print full page
          window.print();
        }}
      >
        Распечатать
      </div>
    </div>
  );
};

// {chunkedIds.map((ids, index) => (
//     <div id={`qr-code-grid-${index}`} className="qr-grid" key={index}>
//       {ids.map((item) => {
//         const svg = generateSVG(item.number);
//         const imageUrl = svgToDataURL(svg);
//         return (
//           <div key={item.id} id={`qr-${item.id}`} className="qr-item">
//             {/* <QRCode
//                                     // add S symbol in the middle of the QR code
//                                     includeMargin={true}
//                                     renderAs="svg"
//                                     value={`http://menu.zim-zim.uz/${company_id}?table_id=${item.id}`}
//                                     size={140}
//                                 /> */}

//             <QRCode
//               value={`http://menu.zim-zim.uz/${company_id}?table_id=${item.id}`}
//               size={175}
//               logoImage={imageUrl}
//               logoWidth={50}
//               logoHeight={50}
//               removeQrCodeBehindLogo
//               logoPaddingStyle="circle"
//               eyeRadius={10}
//               qrStyle="dots"
//               renderAs="svg"
//             />
//             <p
//               style={{
//                 // marginTop: "10px",
//                 fontSize: "18px",
//                 // position: "absolute",
//                 // bottom: "-15px",
//               }}
//             >
//               {item.place.title_ru}
//             </p>
//           </div>
//         );
//       })}
//     </div>
//   ))}

export default QrCodeGenerator;
