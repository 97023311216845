import React, { useState, useEffect } from "react";
// axios
import axios from "axios";
// store
import useStore from "../../store";
// react-router-dom
import { useLocation } from "react-router-dom";

export default function Invoice() {
  const { orders, setOrders } = useStore((state) => state);

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const tableId = queryParams.get("table_id");

  const [iframeHeight, setIframeHeight] = useState("1000px");
  const [link, setLink] = useState("");

  async function fetchOrders() {
    if (!tableId) return;
    axios({
      url: `${process.env.REACT_APP_API_URL}online-menu/table/${tableId}/orders`,
      method: "POST",
    })
      .then((response) => {
        if (response?.data?.data) {
          setOrders(response.data.data);
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  useEffect(() => {
    fetchOrders();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    const height = document.querySelector(".ReactModal__Content").offsetHeight;
    setIframeHeight(`${height}`);

    if (orders.length > 0) {
      // if orders length is 1 than link will be like this https://api.zim-zim.uz/api/v1/manager/orders/print-group?ids=2863578&_t=1719387876&silence
      // if orders length is 2 or more than link will be like this https://api.zim-zim.uz/api/v1/manager/orders/print-group?ids=2863578,2863579&_t=1719387876&silence
      let ids = orders.map((order) => order.id).join(",");
      setLink(
        `https://api.zim-zim.uz/api/v1/manager/orders/print-group?ids=${ids}&silence`
      );
    }
  }, [orders]);

  useEffect(() => {
    const height = document.querySelector(".ReactModal__Content").offsetHeight;
    setIframeHeight(`${height}`);

    if (orders.length > 0) {
      // if orders length is 1 than link will be like this https://api.zim-zim.uz/api/v1/manager/orders/print-group?ids=2863578&_t=1719387876&silence
      // if orders length is 2 or more than link will be like this https://api.zim-zim.uz/api/v1/manager/orders/print-group?ids=2863578,2863579&_t=1719387876&silence
      let ids = orders.map((order) => order.id).join(",");
      setLink(
        `https://api.zim-zim.uz/api/v1/manager/orders/print-group?ids=${ids}&silence`
      );
    }
  }, []);

  return (
    <div>
      <iframe
        src={link}
        title="invoice"
        width="100%"
        height={iframeHeight - 50}
        style={{ border: "none" }}
      ></iframe>
    </div>
  );
}
